// See .cache/page-templates after running dev/build
// to understand how this file ends up looking

import React, { useEffect } from "react";
import Img from "gatsby-image";
import VisibilitySensor from "react-visibility-sensor";
import AboutIntro from "../../src/components/about/AboutIntro";
import AboutWhoWeAre from "../../src/components/about/AboutWhoWeAre";
import AboutTheEdge45Way from "../../src/components/about/AboutTheEdge45Way";
import AboutStatement from "../../src/components/about/AboutStatement";
import BasicBrandsLogosHack from "../../src/components/about/BasicBrandLogosHack";
import BasicCallToAction from "../../src/components/page/BasicCallToAction";
import BasicMoreServices from "../../src/components/page/BasicMoreServices";
import "../../src/sass/pages/_about.scss";
import Seo from "../../src/components/seo";
import { SchemaLinksToAbsolute } from "../../src/helpers/SchemaLinksToAbsolute";
import { graphql } from "gatsby";
import VideoTranscriptionBlock from "../../src/components/page/VideoTranscriptionBlock";

const PageTemplate = (pageProps) => {
  const content = pageProps.pageContext.content;
  const seoTitle = pageProps.data.wpPage.seo.title;
  const seoDesc = pageProps.data.wpPage.seo.metaDesc;
  function inviewOnChange(isVisible) {
    return isVisible;
  }

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.querySelector("body").setAttribute("data-bg-color", "bg-white");
      document.querySelector("body").setAttribute("data-class", "");
    }
  }, []);

  const offsetVideo = () => {
    let videoHeight = document.querySelector(
      ".video-wrapper-outer-outer .video-wrapper"
    ).offsetHeight;

    videoHeight += document.querySelector(
      ".video-wrapper-outer-outer .video-transcript-block"
    ).offsetHeight;

    // const approach = document.querySelector(".our-approach");
    const whoWere = document.querySelector(".transformer");
    // approach.style.marginTop = -videoHeight / 2 - 1 + "px";
    whoWere.style.paddingBottom = videoHeight / 2 + "px";

    console.log(videoHeight);
  };

  useEffect(() => {
    if (typeof document !== "undefined") {
      offsetVideo();

      window.addEventListener("resize", offsetVideo);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const scroller = document.querySelector("#container-scroller");
      const anchors = Array.from(document.querySelectorAll('[href^="#"]'));
      const header = document.querySelector("#header");
      const hh = header.offsetHeight;

      if (anchors.length > 0) {
        anchors.map((anchor) => {
          anchor.addEventListener("click", function (e) {
            e.preventDefault();
            console.log("clicked");
            const id = anchor.hash;
            const yourElement = document.querySelector(id);
            const y = yourElement.getBoundingClientRect().top + window.pageYOffset - hh - 35;
            scroller.scrollTo({ top: y, behavior: "smooth" });
          });
        });
      }
    }
  });

  return (
    <div className="outer-component-wrapper about page-template-about">
      {pageProps.pageContext.schema !== undefined ? (
        <SchemaLinksToAbsolute schemaRaw={pageProps.pageContext.schema} />
      ) : (
        ""
      )}
      <Seo
        title={seoTitle}
        description={seoDesc}
        date={pageProps.pageContext.date}
        slug={"/" + pageProps.pageContext.slug + "/"}
        robotsFollow={pageProps.data.wpPage.seo.metaRobotsNofollow}
        robotsIndex={pageProps.data.wpPage.seo.metaRobotsNoindex}
        canonicalUrl={pageProps.data.wpPage.seo.canonical}
      />
      <AboutIntro content={content} />
      <AboutWhoWeAre content={content} />
      <div className="transformer">
        <div className="video-block">
          <div className="title">Life at Edge45®</div>
          <div className="video-wrapper-outer-outer">
            <div className="video-wrapper-outer">
              <div className="video-wrapper">
                <iframe
                  src="https://player.vimeo.com/video/797293233?h=3e905926b5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  title="Life at Edge45&amp;reg;"></iframe>
              </div>
            </div>
            <VideoTranscriptionBlock
              text={`<p>I've actually been at Edge45 for three and a half years now, which is actually the longest I've ever spent at a company, and the reasons for that are simple. They've trusted in me to really take ownership of the projects that I'm working on. I've received a ton of training. It's a really close-knit team of people, and it's one of those places where you just enjoy going to work.</p>
<p>Edge is a really fun agency to work at. It's a good close team. Everyone's gets involved. Everyone chips in. We've got some really interesting clients. You're not just sort of, you know, pigeonholed, and sort of like you are a writer. You are doing strategy, you are doing, you know, the technical side. Everyone does a bit of everything. So you learn really fast. Everyone's really supportive. So everyone's sort of...you know, if you need to figure out how to use a program or some software or a website, there's someone there who knows, and they will run you through it.</p>
<p>The hybrid working. So mix of coming into the office, mix of working from home, I've found that's a fantastic way of working. Throughout my days when I'm at home, I've got my head down, I'm getting through a lot of work, but equally coming into the office, you've got that strong family feel in the office, and everyone gets on really well.</p>
<p>It's not just, you know, work, work, work all the time. There are moments where you can relax a little bit, you can socialize a little bit, and that's really nice as well.</p>
<p>There is always that sort of community atmosphere. Like, there's always people in the office, and I think Colm and Jo have fostered a really nice sort of environment to work in. And everyone's that friendly and sort of positive, and, like, we play Mario Kart on lunch. It's a really nice environment to work in, in that, you know, you're not just a number, you are sort of a member of the team, and you feel special to work here.</p>`}
            />
          </div>
        </div>
        <AboutTheEdge45Way content={content} />
        <AboutStatement content={content} />
        <BasicBrandsLogosHack
          label={"Brands that trust us"}
          removeBottomMargin={false}
          brandLogosGatsby={content.brands}
        />
        <BasicCallToAction
          heading={content.callToAction.ctaHeading}
          buttons={content.callToAction.ctaButtons}
          removeBottomMargin={content.callToAction.addMarginBottom}
        />
        <BasicMoreServices
          removeBottomMargin={content.removeBottomMargin}
          service1={content.pickServices.service1}
          service2={content.pickServices.service2}
          service3={content.pickServices.service3}
          service4={content.pickServices.service4}
          service5={content.pickServices.service5}
        />
      </div>
    </div>
  );
};

export default PageTemplate;


    export const query = graphql`
      query PageQuery12 {
        wpPage(title: {eq: "About"}) { 
          title
          seo{
            title
            metaDesc
            metaRobotsNofollow
            metaRobotsNoindex
            canonical
          }
          
    acf_pageBuilder {
      pageBuilder {
        __typename
         
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicAccordion {
         
        accordion {
            heading
            text
        }
        sectionTitle {
            headingType
            titleText
        }
        removeBottomMargin
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicBigBoldStatementBlockQuote {
        
   
            fieldGroupName
            removeBottomMargin
            statement
            textColour
   
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicBrandLogos {
        
      
        label
        removeBottomMargin
        removeGrayscale
        brandLogosGatsby {
          logo {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicCallToAction {
        
      
        fieldGroupName
        removeBottomMargin
        callToActionGatsby {
          ctaButtons {
            buttonText
            pageLink {
              ... on WpPage {
                id
                link
              }
            }
          }
          ctaHeading
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicFullWidthImage {
        
 
        caption
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        notFullWidth
        removeBottomMargin
 
        
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicFullWidthVideo {
        
      
        removeBottomMargin
        videoEmbedCode
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicGallery {
        
      
        fieldGroupName
        removeBottomMargin
        gallery {
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
              relativePath
            }
          }
        }
       
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicImageGrid {
        
 
       imageGrid{
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
       }
        removeBottomMargin
 
        
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicMoreServices {
        
      
        removeBottomMargin
        pickServicesGatsby {
          service1 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service2 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service3 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service4 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service5 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicRelatedPosts {
        
      
        removeBottomMargin
        postPicker {
          buttonText
          headline
          label
          rolloverText
          pickPost {
            ... on WpBlog {
              id
              link
              title
              acf_customFeaturedImage {
                customFeaturedImage {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        }
                    }
                  }
                }
              }
            }
            ... on WpCaseStudy {
              id
              link
              title
              acf_caseStudyFields {
                backgroundImage {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        }
                    }
                  }
                }
              }
            }
          }
          rolloverText
        }
        text
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicTable {
        
      
        fieldGroupName
        removeBottomMargin
        tableRow {
          isHeaderRow
          highlightRow
          tableColumns {
            tableCell
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicTestimonial {
        
      
        fieldGroupName
        personPositionCompany
        personPhoto {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        personPhotoOffsets {
          offsetX
          offsetY
        }
        quotation
        removeBottomMargin
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicTestimonialSlider {
        
        fieldGroupName
        testimonialSlider{
          personPositionCompany
          personPhoto {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
          personPhotoOffsets {
            offsetX
            offsetY
          }
          quotation
        }
        removeBottomMargin
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicTextBlock {
        
      
        addDividerLine
        removeBottomMargin
        text

    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_BasicTextBlock2col {
        
      
        addDividerLine
        removeBottomMargin
        text
        text2
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyAnimatedStatisticArrow {
        
      
      removeBottomMargin
      animatedStatisticWArrow {
        fieldGroupName
        label
        number
        symbol
      }
    
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyAnimatedStatistics6BackgroundImage {
        
        
          fieldGroupName
          heading
          backgroundImage {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
          removeBottomMargin
          text
          statistics {
            addColourBackground
            fieldGroupName
            label
            number
            symbol
          }
        
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyHeroImageBlock {
        
      
        backgroundImageFocalPoint
        backgroundImageOpacity
        backgroundImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        heading
        logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        logoWidth
        removeBottomMargin
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyNumberedSlider {
        
      
        removeBottomMargin
        slider {
          imageType
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
          text
        }
        sliderLabel
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyParallaxImageText {
        
      
        backgroundColour1
        backgroundColour2
        removeBottomMargin
        text
        parallaxImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyQuotation {
        
      
        labelHeading
        personPhoto {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        quotationCompany
        quotationCompanyLink
        quotationPerson
        quotationPosition
        personPositionCompany
        quotation
        removeBottomMargin
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyQuotationSlider {
        
      quotations {
        labelHeading
        personPhoto {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        quotationCompany
        quotationCompanyLink
        quotationPerson
        quotationPosition
        personPositionCompany
        quotation
      }
      removeBottomMargin
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_CaseStudyTextStatBackgroundImage {
        
      
        removeBottomMargin
        backgroundImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        text
        statisticGatsby {
          label
          number
          symbol
        }
    
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceBarChartTextButton {
        
      
        fieldGroupName
        removeBottomMargin
        barChart {
          chartTitle
          dataValues {
            dataValue
            xDataValue
          }
          tooltipXLabelOptions
          tooltipXUnitOptions
          tooltipYLabelOptions
          tooltipYUnitOptions
          xLabel
          xUnitLabel
          yLabel
          yUnitLabel
        }
        barChart2datasets {
          chartTitle
          tooltipXLabelOptions
          tooltipXUnitOptions
          tooltipYLabelOptions
          tooltipYUnitOptions
          xLabel
          xUnitLabel
          yLabel
          yUnitLabel
          dataSets {
            dataSetColour
            dataSetTitle
            dataValues {
              dataValue
              xDataValue
            }
          }
        }
        singleOrMultipleDatasets
        text
        button {
          buttonColour
          buttonLink {
            ... on WpPage {
              id
              link
            }
          }
          buttonText
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceCallToActionWImage {
        
      
        backgroundColor1
        backgroundColor2
        heading
        headingTag
        removeBottomMargin
        text
        buttonGatsby {
          buttonColour
          buttonLink {
            ... on WpPage {
              link
              title
            }
          }
          buttonText
        }
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceIntroSection {
        
        addCountdownTimer
        countdownFrom
        heading
        removeBottomMargin
        scrollingBackgroundText
        agencyPrefixText
        source
        text
        ctaButton{
            target
              title
              url
        }
        animatedIconVideoUrl
        specifyParentPage {
            ... on WpService {
                uri
                title
                acf_serviceFields {
                    abbreviatedTitle
                    longTitle
                }
            }
        }
        specifyGrandparentPage {
            ... on WpService {
                uri
                title
                acf_serviceFields {
                    abbreviatedTitle
                    longTitle
                }
            }
        }
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceLineChartTextButton {
        
      
        removeBottomMargin
        lineChart {
          dataSets {
            dataSetColour
            dataSetTitle
            dataValues {
              dataValue
              xDataValue
            }
          }
          chartTitle
          tooltipXLabelOptions
          tooltipXUnitOptions
          tooltipYLabelOptions
          tooltipYUnitOptions
          xLabel
          xLabelCustomTooltip
          xUnitLabel
          xUnitLabelCustomTooltip
          yLabelCustomTooltip
          yLabel
          yUnitLabel
          yUnitLabelCustomTooltip
        }
        button {
          buttonColour
          buttonLink {
            ... on WpPage {
              link
            }
          }
          buttonText
        }
        text
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceTextImage {
        
        useCustomBackgroundColour
        backgroundColour1
        backgroundColour2
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        imageFocalPoint
        imageToFillSpace
        label
        padding {
          paddingBottom
          paddingLeft
          paddingRight
          paddingTop
        }
        removeBottomMargin
        text
        textimagebutton{
          textimagebuttontext
          textimagebuttonlink
        }
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServiceTextVideo {
        
      
        backgroundColour1
        backgroundColour2
        imageToFillSpace
        video
        removeBottomMargin
        text
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_Services3Image {
        
      
        removeBottomMargin
        imagesX3 {
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServicesServicesGridVideoTitleText {
        
      
        removeBottomMargin
        servicesServicesGridVideoTitleText {
          videoUrl
          text
          title
          linkToService {
            ... on WpService {
              uri
            }
          }
        }
        servicesGridHeading
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_ServicesStaffBlock {
        
      
        servicesStaffBlockHeading
        servicesStaffBlockIntroText
        servicesStaffRepeater{
            text
            staffMember{
                ... on WpTeamMember{
                    title
                    slug
                    acf_staff_member{
                        colour
                        image{
                            altText
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1920) {
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      
    
      }
     
 
      ... on WpPage_AcfPagebuilder_PageBuilder_VideoTranscriptionBlock {
        


        text

    
      }
    
      }
    }
  
        }
      }
    `
  
