import React, {useEffect, useState} from "react"
import VisibilitySensor from 'react-visibility-sensor';
import AboutTheEdge45WayContentComponent from './AboutTheEdge45WayContentComponent'

const AboutTheEdge45WayContent = ({ content, isInView }) => {
  
  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  useEffect(() => {
    if(typeof window !== 'undefined'){
      const content_1 = document.querySelector('#content_1 .text-node');
      const content_2 = document.querySelector('#content_2 .text-node');
      const content_3 = document.querySelector('#content_3 .text-node');
      const nav = document.querySelector('#about_nav_pin');      
      const windowMiddle = window.innerHeight / 2;
      const windowTop = window.innerHeight;
      
      window.addEventListener('scroll', function(e){
        const c1Y = content_1.getBoundingClientRect().top;
        const c2Y = content_2.getBoundingClientRect().top;
        const c3Y = content_3.getBoundingClientRect().top;
        Array.from(nav.querySelectorAll('li')).map(item => item.classList.remove('active'));

        if(c1Y < windowMiddle && c1Y > 0){
          
          nav.querySelector('li[data-slide="#content_1"]').classList.add('active');
        }
        
        if (c2Y < windowMiddle && c2Y > 0){
          
          nav.querySelector('li[data-slide="#content_2"]').classList.add('active');
        }
        
        if (c3Y < windowMiddle && c3Y > 0){
          
          nav.querySelector('li[data-slide="#content_3"]').classList.add('active');
        }
      }, {passive: true})
    }
  }, [])
 

  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
        <div className={`content_wrap clearfix ${isInview ? 'is_inview' : ''}`}>
          {content.carouselOa.map((slide, index) => {
            return(    
              <AboutTheEdge45WayContentComponent slide={slide} index={index} />   
              )
            })}
        </div>  		
    </VisibilitySensor>
  )
}


export default AboutTheEdge45WayContent