import React, {useState} from "react"
import LottieWrap from '../../lottie/lottieWrap'
import { Link } from "gatsby"
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_more-services.scss";


const BasicMoreServices = (data, { removeBottomMargin }) => {

  const service1 = data?.pickServicesGatsby?.service1 || data?.service1;
  const service2 = data?.pickServicesGatsby?.service2 || data?.service2;
  const service3 = data?.pickServicesGatsby?.service3 || data?.service3;
  const service4 = data?.pickServicesGatsby?.service4 || data?.service4;
  const service5 = data?.pickServicesGatsby?.service5 || data?.service5;
  let classList = [
    'more-services', 
    'last-section'
  ]; 

   
  if(removeBottomMargin === true){
    classList.push('no-margin');
  }

  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }
  
  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
    {({isVisible}) =>
      <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`}  data-bg-shape="show_shape">
        <div className="wrapper">
          <div className="label">More services</div>
          
          <div className="services-grid">
            <div className="col-1">
              <div id={service1.acf_serviceFields.abbreviatedTitle.toLowerCase()+"_wrap"} className={service1.acf_serviceFields.abbreviatedTitle.toLowerCase()+" service-1 service"}>
                <div className="bg" data-text={service1.acf_serviceFields.abbreviatedTitle.toLowerCase()}></div>
                <Link to={service1.link}>
                  <div className="service-title">{service1.acf_serviceFields.longTitle}</div>
                  <div className="lottie_wrap"  data-target={service1.acf_serviceFields.abbreviatedTitle.toLowerCase()}>
                    <div className="bodymovin">
                      <LottieWrap animation={service1.acf_serviceFields.abbreviatedTitle.toLowerCase()+'_moreservices'}></LottieWrap>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-2">
              <div id={service2.acf_serviceFields.abbreviatedTitle.toLowerCase()+"_wrap"} className={service2.acf_serviceFields.abbreviatedTitle.toLowerCase()+" service-1 service"}>
                <div className="bg" data-text={service2.acf_serviceFields.abbreviatedTitle.toLowerCase()}></div>
                <Link to={service2.link}>
                  <div className="service-title">{service2.acf_serviceFields.longTitle}</div>
                  <div className="lottie_wrap"  data-target={service2.acf_serviceFields.abbreviatedTitle.toLowerCase()}>
                    <div className="bodymovin">
                      <LottieWrap animation={service2.acf_serviceFields.abbreviatedTitle.toLowerCase()+'_moreservices'}></LottieWrap>
                    </div>
                  </div>
                </Link>
              </div>
              <div id={service3.acf_serviceFields.abbreviatedTitle.toLowerCase()+"_wrap"} className={service3.acf_serviceFields.abbreviatedTitle.toLowerCase()+" service-1 service"}>
                <div className="bg" data-text={service3.acf_serviceFields.abbreviatedTitle.toLowerCase()}></div>
                <Link to={service3.link}>
                  <div className="service-title">{service3.acf_serviceFields.longTitle}</div>
                  <div className="lottie_wrap"  data-target={service3.acf_serviceFields.abbreviatedTitle.toLowerCase()}>
                    <div className="bodymovin">
                      <LottieWrap animation={service3.acf_serviceFields.abbreviatedTitle.toLowerCase()+'_moreservices'}></LottieWrap>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-3">
              <div id={service4.acf_serviceFields.abbreviatedTitle.toLowerCase()+"_wrap"} className={service4.acf_serviceFields.abbreviatedTitle.toLowerCase()+" service-1 service"}>
                <div className="bg"  data-text={service4.acf_serviceFields.abbreviatedTitle.toLowerCase()}></div>
                <Link to={service4.link}>
                  <div className="service-title">{service4.acf_serviceFields.longTitle}</div>
                  <div className="lottie_wrap"  data-target={service4.acf_serviceFields.abbreviatedTitle.toLowerCase()}>
                    <div className="bodymovin">
                      <LottieWrap animation={service4.acf_serviceFields.abbreviatedTitle.toLowerCase()+'_moreservices'}></LottieWrap>
                    </div>
                  </div>
                </Link>
              </div>
              <div id={service5.acf_serviceFields.abbreviatedTitle.toLowerCase()+"_wrap"} className={service5.acf_serviceFields.abbreviatedTitle.toLowerCase()+" service-1 service"}>
                <div className="bg" data-text={service5.acf_serviceFields.abbreviatedTitle.toLowerCase()}></div>
                <Link to={service5.link}>
                  <div className="service-title">{service5.acf_serviceFields.longTitle}</div>
                  <div className="lottie_wrap"  data-target={service5.acf_serviceFields.abbreviatedTitle.toLowerCase()}>
                    <div className="bodymovin">
                      <LottieWrap animation={service5.acf_serviceFields.abbreviatedTitle.toLowerCase()+'_moreservices'}></LottieWrap>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>   
        </section>
    }
  </VisibilitySensor>
    
  )
}

export default BasicMoreServices

