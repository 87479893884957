import React, { useState } from "react";
import parse from "html-react-parser";
import VisibilitySensor from "react-visibility-sensor";
import AboutTheEdge45WayContent from "./AboutTheEdge45WayContent";

const AboutTheEdge45Way = ({ content, isInView }) => {
  const [isInview, setIsInview] = useState(false);

  function inviewOnChange(isVisible) {
    setIsInview(isVisible);
  }

  const handleScroll = (e) => {
    let target = e.target.closest("a").getAttribute("href");
    document
      .querySelector(target)
      .scrollIntoView({ behavior: "smooth", block: "center" });
    e.preventDefault();
  };

  return (
    <VisibilitySensor
      partialVisibility={true}
      offset={{ bottom: 120 }}
      active={!isInview}
      onChange={inviewOnChange}
    >
      {({ isVisible }) => (
        <section
          className={`our-approach section  ${isInview ? "is_inview" : ""}`}
          data-color="blue"
        >
          <h2 className="title">{content.titleOa}</h2>
          <div className="intro-text">{parse(content.textOa)}</div>

          <div className="slider">
            <ul className="slider-nav" id="about_nav_pin">
              {content.carouselOa.map((slide, index) => {
                return (
                  <li data-slide={`#content_${index + 1}`}>
                    <a
                      onClick={(e) => handleScroll(e)}
                      href={`#content_${index + 1}_text`}
                    >
                      <span className="number">{index + 1}</span>
                      <span className="label">{slide.title}</span>
                    </a>
                  </li>
                );
              })}
            </ul>

            <AboutTheEdge45WayContent content={content} />
          </div>
        </section>
      )}
    </VisibilitySensor>
  );
};

export default AboutTheEdge45Way;
