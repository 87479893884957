import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { Link } from "gatsby";
import Image from "../page/ResuableImageWrapper";
import VisibilitySensor from "react-visibility-sensor";
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";

gsap.registerPlugin(SplitText);

const AboutWhoWeAre = ({ content, isInView }) => {
  const [isInview, setIsInview] = useState(false);

  function inviewOnChange(isVisible) {
    setIsInview(isVisible);
  }
  useEffect(() => {
    if (typeof document !== "undefined") {
      // let text = heroText.innerHTML;
      var heroText_split = new SplitText(
        ".who_we_are .text-cont .fakeHeading span",
        { type: "lines", linesClass: "lines" }
      );

      heroText_split.lines.map((line) => {
        //console.log(line.innerHTML)
        let grandparent = line.parentNode.parentNode;
        let parent = line.parentNode;
        let originalHtml = line.innerHTML;
        let newHtml = `<div>${originalHtml}</div>`;
        line.innerHTML = newHtml;

        grandparent.append(line);
        Array.from(grandparent.querySelectorAll(".lines")).map((line) => {
          setTimeout(
            function () {
              line.classList.add("is_inview");
            }.bind(this),
            1
          );
        });
        parent.style.display = "none";
      });

      //console.log(heroText_split);
    }
  }, []);

  return (
    <VisibilitySensor
      partialVisibility={true}
      offset={{ bottom: 120 }}
      active={!isInview}
      onChange={inviewOnChange}
    >
      {({ isVisible }) => (
        <div className="who_we_are">
          <section
            className={`who_we_are--inner clearfix  ${
              isInview ? "is_inview" : ""
            }`}
          >
            <div className="wrapper-outer">
              <div className="wrapper-large">
                <div className="text-cont">
                  <h2 className="label">{content.labelWwa}</h2>
                  <div className="fakeHeading">
                    <span>{content.headingWwa}</span>
                  </div>
                  <div className="text">
                    <div className="text--inner">{parse(content.textWwa)}</div>
                    <Link
                      to="/team/"
                      className="btn btn--grad btn--grad--orange"
                    >
                      <span>Meet the team</span>
                    </Link>
                  </div>
                </div>
                <div className="background-image">
                  <Image
                    image={content?.imageWwa?.localFile?.childImageSharp?.fluid}
                  />
                  {content?.imageWwa?.localFile?.childImageSharp?.fluid
                    ? ""
                    : "image not found"}
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </VisibilitySensor>
  );
};

export default AboutWhoWeAre;
