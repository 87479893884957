import React, {useState} from 'react'
import parse from 'html-react-parser'
import Image from '../page/ResuableImageWrapper';
import VisibilitySensor from 'react-visibility-sensor';

export default function AboutTheEdge45WayContentComponent({slide, index}) {

  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      <div id={`content_${index + 1}`} className={`content ${isInview ? 'is_inview' : ''}`}>
        <div className="images">
          <div className="image-node" data-order="1">
          <div className="image-1">
              <Image alt={slide?.image1?.altText} image={slide?.image1?.localFile?.childImageSharp?.fluid} />
              {slide?.image1?.localFile?.childImageSharp?.fluid ? "" : 'image not found'}
            </div>	
            <div className="image-2">
              <Image alt={slide?.image2?.altText} image={slide?.image2?.localFile?.childImageSharp?.fluid} />
              {slide?.image2?.localFile?.childImageSharp?.fluid ? "" : 'image not found'}
            </div>	
            <div className="image-3">
              <Image alt={slide?.image3?.altText} image={slide?.image3?.localFile?.childImageSharp?.fluid} />
              {slide?.image3?.localFile?.childImageSharp?.fluid ? "" : 'image not found'}
            </div>	
          </div>
        </div>
        <div className="text">
        <div className="text-node"  id={`content_${index + 1}_text`}>
            <h3 className="title">{slide.title}</h3>
            {parse(slide.text)}
          </div>
        </div>
      </div>   
    </VisibilitySensor>
  )
}
