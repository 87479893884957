import React, {useState} from "react"
import VisibilitySensor from 'react-visibility-sensor';

const AboutTheEdge45Way = ({ content, isInView }) => {
  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }
  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section className={`statement section ${isInview ? 'is_inview' : ''}`}>
          <h3>{content.statement}</h3>
          <span>{content.statementSmallText}</span>
        </section>
      }
    </VisibilitySensor>
   
  )
}


export default AboutTheEdge45Way