import React, {useEffect, useState} from "react"
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";
import Icon45 from '../../assets/about45.inline.svg';
import parse from 'html-react-parser'
import Video from "../../assets/video/about_animation.mp4"
import VisibilitySensor from 'react-visibility-sensor';;

gsap.registerPlugin(SplitText);


const AboutIntro = (data) => {
  
  const [isInview, setIsInview] = useState(false);
  const [isVideoInview, setIsVideoInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }
  function inviewOnChangeVideo (isVisible) {
    setIsVideoInview(isVisible)
      
    if(isVisible === true){
      const svg = document.querySelector('.massive_text svg')
      const vid = document.querySelector('#video')
      vid.play()
      setTimeout(function(){
        svg.classList.add('animate')
      },300)
    }
  }

  useEffect(() => {
    if(typeof document !== 'undefined'){

      
      // let text = heroText.innerHTML;
		  	var heroText_split = new SplitText('.intro .text-cont .fakeHeading span', {type:"lines", linesClass:'lines'});

        heroText_split.lines.map(line => {
          let grandparent = line.parentNode.parentNode;
          let parent = line.parentNode;
          let originalHtml = line.innerHTML;
          let newHtml = `<div>${originalHtml}</div>`

          line.innerHTML = newHtml;

          grandparent.append(line);
          Array.from(grandparent.querySelectorAll('.lines')).map(line => {
            setTimeout(function(){
              line.classList.add('is_inview')
            }.bind(this), 1);
              
          })
          parent.style.display = 'none';

        })

    }
    
  
  }, [])

  

  //console.log(data)
  return (
    // <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
    //   {({isVisible}) =>
         <section className={`intro section clearfix ${isInview ? 'is_inview' : 'is_inview'}`}>
          <div className="main-text">
              <div className="wrapper-large">
                <div className="massive_text">
                    <Icon45 />
                </div>
                <div className="text-cont">
                  <h1 className="label">{data.content.label}</h1>
                  <div className="fakeHeading"><span>{data.content.heading}</span></div>
                  <div className="text">
                    <div className="text--inner">{parse(data.content.text)}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="video-wrapper">  
              <video id="video" playsInline muted >
                <source src={Video} type="video/mp4" ></source>
              </video>
            <VisibilitySensor active={true}  active={!isVideoInview} onChange={inviewOnChangeVideo}>
                {({isVisible}) =>  
                 <div className="fakeVideo">I am {isVisible ? 'visible' : 'invisible'}</div>
                }
              </VisibilitySensor>
            </div>

          </section>
    //   }
    // </VisibilitySensor>
   
      
  )
}


export default AboutIntro